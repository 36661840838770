$('document').ready(function() {
  // Buger Menu
  $(".burger-nav").on("click", function() {
    $("header nav ul.menu").toggleClass("open");
  });

  // Abrir Submenús
  $(".selector").on("click", function(e) {
    console.log($(e.target).parent());
    var parent = $(e.target).parent();
    $(parent).children('ul').toggleClass("open");
  });

  // Modal de Horóscopos
  $('#myBtn').click(function(e) {
    $('#modal-header').text($(e.target).data('nombre'));
    $('#modal-body').text($(e.target).data('horoscopo'));
    $("#myModal").css("display", "block");
  });

  $('.close').click(function(e) {
    $("#myModal").css("display", "none");
  });

  $(window).click(function(e) {
    if (e.target.id == 'myModal') {
      $("#myModal").css("display", "none");
    }
  });

  // Open Radio Window
  $('.open-floating-window').click(function(e) {
    e.preventDefault();
    openOnce(this.href, 'MyWindowName');
  });
});

// Componentes del Sidebar
Vue.component('clima', {

  props: ['ciudades'],

  data: {
    publicidadParaClima: publicidadParaClima
  },

  methods: {
    iconoClima: function(ciudad) {
      return 'owf-' + ciudad.weather[0].id;
    }
  },

  updated: function() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been re-rendered
      $('.slick-clima').slick({
        autoplay: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
      });
    });
  },

  template: `
    <section class="sidebar-clima slick-clima">
        <div class="ciudad" v-for="ciudad in ciudades">
            <div class="publicidad publicidad-patrocinadores-puentes">
                <img class="img-fluid" src="https://www.notigape.com/thumburl/thumbnail/600x90/outbound/uploads/advertising/${publicidadParaClima}"
                alt="La Publicidad">
            </div>

            <div class="clima">
                <div class="ciudad">
                    <h3>{{ ciudad.name }}</h3>
                    <div class="min-max">
                    <h3>{{ ciudad.main.temp_min }}˚</h3>
                    <h3>{{ ciudad.main.temp_max }}˚</h3>
                    </div>
                </div>

                <div class="temperatura">
                    <h4>{{ Math.round(ciudad.main.temp) }}˚</h4>
                </div>

                <div class="icono">
                    <i :class="iconoClima(ciudad)" class="owf"></i>
                </div>
            </div>

            <div class="mensaje">
                <h4>{{ ciudad.weather[0].description }}</h4>
            </div>
        </div>
    </section>
    `
});

Vue.component('puentes', {

  props: ['puentes'],

  data: {
    publicidadPuentesDolar: publicidadPuentesDolar
  },

  updated: function() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been re-rendered
      $('.slick-puentes').slick({
        autoplay: true,

        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
      });
    });
  },

  methods: {
    pasajeros: function(puente) {
      var data = puente.passenger_vehicle_lanes;

      if (!isNaN(data.standard_lanes.lanes_open)) {
        return {
          icono: 'fa-automobile',
          delay: data.standard_lanes.delay_minutes
        }
      }

      return false;
    },

    comercial: function(puente) {
      var data = puente.commercial_vehicle_lanes;

      if (!isNaN(data.standard_lanes.lanes_open)) {
        return {
          icono: 'fa-bus',
          delay: data.standard_lanes.delay_minutes
        }
      }

      return false;
    },

    peatonal: function(puente) {
      var data = puente.pedestrian_lanes;

      if (!isNaN(data.standard_lanes.lanes_open)) {
        return {
          icono: 'fa-street-view',
          delay: data.standard_lanes.delay_minutes
        };
      }

      return false;
    },

    nombreCruce: function(puente) {
      return (Object.keys(puente.crossing_name).length) ? puente.crossing_name : puente.port_name;
    }
  },

  template: `
    <section class="sidebar-puentes">
        <h3 class="renglon-azul">Puentes Internacionales</h3>

        <div class="slick-puentes">
            <div v-for="(ciudad, index) in puentes">
                <div class="ciudad">
                    <h3 class="nombre">{{ index }}</h3>

                    <div v-for="puente in ciudad">
						<div v-show="pasajeros(puente) || comercial(puente) || peatonal(puente)" class="puente-block">
							<h3 class="nombre">{{ nombreCruce(puente) }}</h3>

							<botonpuente v-show="pasajeros(puente)" :lane="pasajeros(puente)"></botonpuente>

							<botonpuente v-show="comercial(puente)" :lane="comercial(puente)"></botonpuente>

							<botonpuente v-show="peatonal(puente)" :lane="peatonal(puente)"></botonpuente>
						</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="publicidad publicidad-patrocinadores-puentes">
            <img class="img-fluid" src="https://www.notigape.com/thumburl/thumbnail/600x90/outbound/uploads/advertising/${publicidadPuentesDolar}"
            alt="La Publicidad">
        </div>
    </section>
    `
});

Vue.component('botonpuente', {
  props: ['lane'],

  data: function() {
    return {
      icono: ''
    }
  },

  updated: function() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been re-rendered
      $('.slick-puentes').slick({
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
      });
    });
  },

  template: `<div class="info">
        <div class="icono">
        	<i :class="iconoClasses"></i>
		</div>

        <div class="tiempo">
        	<p :class="colorClass">{{ tiempo }}  mins.</p>
        </div>
    </div>
    `,

  computed: {
    tiempo: function() {
      if (!isNaN(this.lane.delay)) {
        return this.lane.delay;
      }
      return;
    },

    iconoClasses: function() {
      return ['fa', this.lane.icono];
    },

    colorClass: function() {
      if (this.lane.delay < 30) {
        return 'green';
      } else if (this.lane.delay < 60) {
        return 'yellow';
      } else if (this.lane.delay >= 60) {
        return 'red';
      }
    }
  }
});

var app = new Vue({
  el: "#sidebar",

  data: {
    ciudades: [],
    APPID: 'e3681cf1d2724b10bfee259ea7ca8697',

    compra: 0,
    venta: 0,

    puentes: []
  },

  computed: {
    ids: function() {
      var ids = '';

      for (var i = 0; i < regiones.length; i++) {
        if (regiones[i].city_id) {
          ids += regiones[i].city_id + ','
        }
      };

      return ids.replace(/,(\s+)?$/, '');
    }
  },

  methods: {
    cargarClima: function() {
      var self = this;

      axios.get('https://api.openweathermap.org/data/2.5/group?id=' + this.ids + '&units=metric&lang=sp&APPID=' + this.APPID).then(function(response) {

        self.ciudades = response.data.list;

      }).catch(function(error) {
        console.log('Error de Openweathermap:', error);
      });
    },

    cargarPuentes: function() {
      var url = '/puentes';

      var self = this;

      axios({
        url: url,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function(response) {
        // console.log('Todos los Pueertos:', response.data.port);

        var returnedData = response.data;

        var puentesInteres = ['535501', '535504', '535503', '535502', '230503', '230501', '230502', '230401', '230402', '230403', '230404', '230701', '231001', '230902', '230901'];

        var losBuenos = [];

        _.each(returnedData.port, function(puente) {
          if (_.contains(puentesInteres, puente.port_number)) {
            losBuenos.push(puente);
          }
        });

        self.puentes = agruparPorCiudad(losBuenos);

      }).catch(function(error) {
        console.log("Error de apps.cbp.gov:", error);
      });
    },

    cargarDolar: function() {
      var url = '/dolar';

      var self = this;

      axios.get(url)
        .then(function(response) {
          var rate = Number(response.data.rate);

          self.compra = '$' + Number(rate - 1).toFixed(2);

          self.venta = '$' + Number(rate + 0.20).toFixed(2);

        }).catch(function(error) {
          console.log("Error de currency-api:", error);
        });
    },
  },

  mounted: function() {
    this.cargarClima();
    this.cargarDolar();
    this.cargarPuentes();
  }
});

function agruparPorCiudad(puentes) {
  return _.groupBy(puentes, function(puente) {
    return puente.port_name;
  });
}

function xmlToJson(xml) {
  // https://github.com/robertomiranda/xml2json
  // https://davidwalsh.name/convert-xml-json
  // Changes XML to JSON
  // Create the return object

  var obj = {};

  // element
  if (xml.nodeType === 1) {
    // do attributes
    if (xml.attributes.length > 0) {
      obj['@attributes'] = {};
      for (var j = 0; j < xml.attributes.length; j++) {
        var attribute = xml.attributes.item(j);
        obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
      }
    }

    // text
  } else if (xml.nodeType === 3) {
    obj = xml.nodeValue;
  }

  // do children
  if (xml.hasChildNodes()) {
    for (var i = 0; i < xml.childNodes.length; i++) {
      var item = xml.childNodes.item(i);

      var nodeName = (item.nodeName === '#text') ? 'text' : item.nodeName;

      if (typeof (obj[nodeName]) === 'undefined') {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (typeof (obj[nodeName].length) === 'undefined') {
          var old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }

  return obj;
};

function openOnce(url, target) {
  // open a blank "target" window
  // or get the reference to the existing "target" window
  var winref = window.open('', target, 'width=320, height=569', true);

  // if the "target" window was just opened, change its url
  if (winref.location.href !== url) {
    winref.location.href = url;
  }
  return winref;
}

// Para debloquear las noticias
/*
// */
// jQuery(document).ready(function ($) {
//     $("body").bind("copy paste", function (event) {
//         var navType = $("body").attr("data-browsername");

//         if (navType == "MSIE") {
//             window.clipboardData.setData("Text", "Este material cuenta con derechos de propiedad intelectual. De no existir previa autorización por escrito de NotiGAPE - Líderes en Noticias, queda expresamente prohibida la publicación, retransmisión, distribución, venta, edición y cualquier otro uso de los contenidos (Incluyendo, pero no limitado a, contenido, texto, fotografías, audios, videos y logotipos). Muchas gracias.");
//         } else {
//             event.originalEvent.clipboardData.setData("Text", "Este material cuenta con derechos de propiedad intelectual. De no existir previa autorización por escrito de NotiGAPE - Líderes en Noticias, queda expresamente prohibida la publicación, retransmisión, distribución, venta, edición y cualquier otro uso de los contenidos (Incluyendo, pero no limitado a, contenido, texto, fotografías, audios, videos y logotipos). Muchas gracias.");
//         }
//         event.preventDefault();

//         return false;
//     });
// });
